import React, { useEffect, useState } from "react";
import { FormatExecutionString } from "../../utils";
import axios from "axios";
import config from "../../config";
import "./SinglePackage.css";

export default function SinglePackage({
  subject1,
  subject2,
  preparation,
  execution,
  otherDescription,
  mainPicturePath,
  name,
  packageID,
  keyID,
  getfiles,
  fK_PackageID,
  instruction,
  title,
}) {
  const [selectedexerciseParameters, setselectedexerciseParameters] = useState(
    []
  );

  useEffect(() => {
    !fK_PackageID && getParametersBypkgandexerc();
  }, []);

  const exerciseDetails = fK_PackageID
    ? null
    : `<div>
  <span>${subject1}</span>
  <ul>${FormatExecutionString(preparation)}</ul>
  <span>${subject2}</span>
  <ul>${FormatExecutionString(execution)}</ul>
  <span>توضیحات تکمیلی</span>
  <ul>${FormatExecutionString(otherDescription)}</ul>
</div>`;

  const getParametersBypkgandexerc = () => {
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    axios
      .get(
        config.hostname +
          `/api/v1/Package/${packageID}/Exercises/${keyID}/Parameters`,
        { headers }
      )
      .then((res) => setselectedexerciseParameters(res.data))
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  return (
    <div className="exercise-box">
      <div className="exercise">
        {fK_PackageID ? (
          <div>
            <h3 style={{ padding: "1rem", textShadow: "0 3px 3px #999" }}>
              {title}
            </h3>
            <h5 style={{ textAlign: "center", padding: "0 1rem 1rem" }}>
              {instruction}
            </h5>
          </div>
        ) : (
          <>
            <div className="div1">
              <img src={`${mainPicturePath}`} alt={name} />
            </div>

            <h3>پارامترها</h3>
            <div className="exercise-parameters">
              {selectedexerciseParameters?.map((parameter) => (
                <div key={parameter.keyID}>
                  <span className="param-name">{parameter.paramName}</span>:{" "}
                  <span>{parameter.paramValue}</span>
                </div>
              ))}
            </div>

            <div className="exercise-content">
              <h2>{name}</h2>

              <div
                className="client-exercise-details"
                dangerouslySetInnerHTML={{
                  __html: exerciseDetails,
                }}
              ></div>

              {/* <div className="div3">
            <button
              onClick={() =>
                showDetails(`mydiv${keyID}-${packageID}`, packageID, keyID)
              }
              className="button"
              id="mybutton"
            >
              مشاهده
            </button>
          </div> */}
              <div id={`mydiv${keyID}-${packageID}`} className="mydiv active">
                <button onClick={() => getfiles(true, keyID)}>
                  ویدئو و تصاویر
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
