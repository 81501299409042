import { useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { Cancel } from "@mui/icons-material";
import Swal from "sweetalert2";
import "./NewTicketModal.css";

export default function NewTicketModal({
  setIsNewTicketBoxOpen,
  isNewTicketBoxOpen,
  receiverID,
  getAllTickets,
}) {
  const ticketInitialValue = {
    subject: "",
    content: "",
  };

  const [newTicket, setNewTicket] = useState(ticketInitialValue);
  const [filterValue, setFilterValue] = useState("2");

  function closeNewTicketBox() {
    setIsNewTicketBoxOpen(false);
    setNewTicket(ticketInitialValue);
  }

  function sendNewTicket(e) {
    e.preventDefault();

    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      const newTicketObj = {
        keyID: +filterValue,
        subject: newTicket.subject,
        description: "string",
        reciverID: "9c2ee532-0218-4826-a2c0-c26acf6daacc",
        priority: 0,
        status: 0,
        newMessage: newTicket.content,
      };

      console.log("new ticket object", newTicketObj);

      fetch(`https://api.bettermove.ir/api/v1/Ticket`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newTicketObj),
      })
        .then((res) => {
          console.log("new ticket res", res);

          Swal.fire({
            title: "پیام با موفقیت ارسال شد",
            icon: "success",
          });

          setNewTicket(ticketInitialValue);
          getAllTickets();
          closeNewTicketBox();
        })
        .catch((error) => console.log("send meessage error", error));
    }
  }

  return (
    <Dialog
      className="new-ticket-modal"
      open={isNewTicketBoxOpen}
      onClose={closeNewTicketBox}
    >
      <Box className="top-new-ticket">
        <DialogTitle
          style={{
            fontFamily: "IRANSans",
            fontSize: "2rem",
          }}
        >
          پیام جدید
        </DialogTitle>
        <IconButton
          className="btn-close-modal"
          style={{ marginLeft: "1rem" }}
          onClick={closeNewTicketBox}
        >
          <Cancel style={{ color: "rgba(176,56,60,255)", fontSize: "2rem" }} />
        </IconButton>
      </Box>

      <DialogContent>
        <div className="filter-receiver-type">
          <FormControl
            className="select-box"
            fullWidth
            variant="outlined"
            margin="normal"
          >
            <InputLabel
              id="filter-label"
              style={{ backgroundColor: "#F8F8F8" }}
            >
              گیرنده پیام
            </InputLabel>

            <Select
              labelId="filter-label"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              label="فیلتر براساس گیرنده"
              style={{ fontFamily: "IRANSans", fontSize: "1rem" }}
            >
              {/* <MenuItem value="1">ادمین</MenuItem> */}
              <MenuItem value="2">پزشک</MenuItem>
            </Select>
          </FormControl>
        </div>
        <FormControl fullWidth margin="normal">
          <TextField
            label="موضوع"
            name="subject"
            value={newTicket.subject}
            onChange={(e) =>
              setNewTicket({
                ...newTicket,
                subject: e.target.value,
              })
            }
            variant="outlined"
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="توضیحات"
            name="content"
            value={newTicket.content}
            onChange={(e) =>
              setNewTicket({
                ...newTicket,
                content: e.target.value,
              })
            }
            variant="outlined"
            fullWidth
            multiline
            rows={4}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={sendNewTicket} className="btn-send">
          ارسال پیام
        </Button>
      </DialogActions>
    </Dialog>
  );
}
