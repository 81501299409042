import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"; // اضافه کردن useParams از react-router-dom

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, InputLabel, Link, OutlinedInput } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import AuthContext from "../../Contexts/authContext";

//import swal from 'sweetalert';
import Swal from "sweetalert2";

import ReCAPTCHA from "react-google-recaptcha";

import "./Login.css";
import config from "../../config";
import Loader from "../../Components/Loader/Loader";

const defaultTheme = createTheme();

const styles = {
  blurredBackground: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  videoBackground: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
};

export default function SignInSide() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  
  const [showPassword, setShowPassword] = useState(false);
  const [isGoogleRecaotchVerify, setIsGoogleRecaotchVerify] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const location = useLocation();
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    console.log("", location.pathname);
  }, []);

  // fetch lohin api & save in browser
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsSubmitting(true);

    const username = window.location.pathname.split("/link/")[1];
    const userData = {
      userName: `user${username}`,
      password: data.get("password"),
    };

    console.log(userData, "userdata");

    fetch(config.hostname + "/api/v1/User/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((text) => {
            throw new Error(text);
          });
        } else {
          return res.json();
        }
      })
      .then((result) => {
        setIsFormValid(true);
        console.log(result); // acces Token
        authContext.login({}, result[0]);
        setIsSubmitting(false);

        Swal.fire({
          title: "با موفقیت لاگین شدید",
          icon: "success",
          confirmButtonText: "ورود به پنل",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          } else {
            Swal.fire("تلاش دوباره", "درخواست تکراری", "error");
          }
        });
      })
      .catch((err) => {
        setIsFormValid(true);

        setIsSubmitting(false);
        Swal.fire({
          title: "نام کاربری یا رمز عبور اشتباه است",
          icon: "error", // نوع آیکون
          showCancelButton: true, // نمایش دکمه انصراف
          showConfirmButton: false,
          cancelButtonText: "تلاش دوباره", // متن دکمه انصراف
          confirmButtonColor: "#3085d6", // رنگ دکمه تایید
          cancelButtonColor: "#d33", // رنگ دکمه انصراف
        }).then((result) => {
          if (result.isConfirmed) {
            // عملیات تایید
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // عملیات انصراف
          }
        });
      });
  };

  const onChangeHandler = () => {
    setIsGoogleRecaotchVerify(true);
  };

  return (
    <MuiRTL>
      <ThemeProvider theme={defaultTheme}>
        <Grid
          container
          component="main"
          sx={{
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <video
            autoPlay
            loop
            muted
            style={styles.videoBackground}
          >
            <source src="./videos/istockphoto.mp4" type="video/mp4" />
            Your browser does not support the video tag.
         </video>*/}

          <Grid
            item
            xs={12}
            sm={8}
            md={7}
            component={Paper}
            elevation={6}
            square
            className="blurredBackground"
          >
            <div className="login-form" style={{ padding: "2rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  sx={{
                    m: 1,
                    bgcolor: "#2f9bfa",
                    width: 100,
                    height: 100,
                    objectFit: "cover",
                  }}
                  // src='images/bettermove.png'
                >
                  <LockOutlinedIcon />
                </Avatar>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontFamily: "IRANSans",
                    color: "#042b89",
                    marginBottom: "1rem",
                  }}
                >
                  ورود به سیستم
                </Typography>
              </div>

              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  style={{ fontFamily: "IRANSans", display: "none" }}
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    style={{ fontFamily: "IRANSans" }}
                    htmlFor="outlined-adornment-password"
                  >
                    نام کاربری
                  </InputLabel>
                  <OutlinedInput
                    required
                    style={{ fontFamily: "IRANSans" }}
                    name="userName"
                    id="userName"
                    type="text"
                    label="نام کاربری"
                    autoFocus
                  />
                </FormControl>

                <FormControl
                  style={{ fontFamily: "IRANSans", marginTop: "1rem" }}
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    style={{ fontFamily: "IRANSans" }}
                    htmlFor="outlined-adornment-password"
                  >
                    رمز
                  </InputLabel>
                  <OutlinedInput
                    required
                    style={{ fontFamily: "IRANSans" }}
                    name="password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="رمز"
                  />
                </FormControl>

                {/* <TextField
                  style={{ fontFamily: "IRANSans" }}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="رمز"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                /> */}

                {/* <div style={{ marginTop: '1rem' }}>
                  <ReCAPTCHA
                  sitekey=" 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={onChangeHandler}
                />
                </div> */}

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  className="login-form-btn-successfuly"
                  // className={`${
                  //   (isGoogleRecaotchVerify)
                  //   ? 'login-form-btn-successfuly'
                  //   : 'login-form-btn-err'}`}
                  // disabled={(!isGoogleRecaotchVerify)}
                >
                  <p style={{ fontFamily: "IRANSans", color: "white" }}>ورود</p>
                </Button>

                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      <p style={{ fontFamily: "IRANSans" }}>
                        رمز خود را فراموش کردید ؟
                      </p>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      <p style={{ fontFamily: "IRANSans" }}>
                        {"اکانت ندارید؟ میخواید ثبت نام کنید؟"}
                      </p>
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </MuiRTL>
  );
}
