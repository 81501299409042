import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function Loader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        margin: "2.5rem",
      }}
    >
      <CircularProgress />
      <div>
        <p
          style={{
            fontFamily: "IRANSans",
            margin: "1.5rem",
            fontSize: "1.2rem",
          }}
        >
          لطفا منتظر بمانید ...
        </p>
      </div>
    </Box>
  );
}
