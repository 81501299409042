import React, { useState, useEffect } from "react";

import { formatDate, formatTime, parseJwt } from "../../utils";

export default function SingleTicketRow({
  setActiveTicket,
  changeDate,
  createDate,
  createByUserID,
  reciverID,
  hasNewmessage,
  subject,
  keyID,
  setopponent,
  setStarterUser,
}) {
  const [receiverName, setReceiverName] = useState("");
  const [starterUserName, setStarterUsername] = useState("");
  const [isNewMessage, setIsNewMessage] = useState(hasNewmessage);

  useEffect(() => {
    console.log("hello");
    const localStorageData = localStorage.getItem("user");
    const user = parseJwt(localStorageData);
    if (createByUserID === user.UserID) {
      setStarterUsername("شما");
      getMyPhysician().then((data) =>
        setReceiverName(
          data.userid === reciverID ? `${data.fName} ${data.lName}` : "ادمین"
        )
      );
    } else {
      getMyPhysician().then((data) =>
        setStarterUsername(
          data.userid === createByUserID
            ? `${data.fName} ${data.lName}`
            : "ادمین"
        )
      );
      setReceiverName("شما");
    }
  }, []);

  function getMyPhysician() {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      return new Promise((resolve, reject) => {
        fetch(`https://api.bettermove.ir/api/v1/Physician/GetMyPsy`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    }
  }

  function showTicket() {
    setActiveTicket(keyID);
    setIsNewMessage(false);
    setopponent({
      userID: reciverID,
      fullname: receiverName,
    });
    setStarterUser({
      userID: createByUserID,
      fullname: starterUserName,
    });
  }
  return (
    <tr onClick={showTicket} className="ticket">
      <td>{starterUserName}</td>
      <td>{receiverName}</td>

      <td className="ticket-subject">{subject}</td>
      <td>
        <span>
          {formatTime(createDate)} | {formatDate(createDate)}
        </span>
      </td>
      <td>
        <span>
          {formatTime(changeDate)} | {formatDate(changeDate)}
        </span>
      </td>
      <td>
        {isNewMessage ? (
          <span className="new-ticket-notif">جدید</span>
        ) : (
          "خوانده شده"
        )}
      </td>
      {/* <td>
        <Delete
          onClick={deleteTicket}
          className="delete-ticket"
          fontSize="large"
        />
      </td> */}
    </tr>
  );
}
