//import React from 'react';
import React, { useState, useEffect, useCallback } from "react";
import "./App.css";
import routes from "./routes";
import { useRoutes } from "react-router-dom";
import Topbar from "./Components/topbar/Topbar";
import Sidebar from "./Components/sidebar/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthContext from "./Contexts/authContext";
import { SelectedGroupProvider } from "./Contexts/SelectedGroup";
import config from "./config";
import axios from "axios";
import { ErrorCode } from "react-dropzone";
import { isDeprecated, parseJwt } from "./utils";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(false);
  const [userInfose, setUserInfose] = useState({});
  const [localStorageToken, setLocalStorageToken] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const login = useCallback((userInfose, token) => {
    setToken(token);
    setIsLoggedIn(true);
    setUserInfose(userInfose);
    localStorage.setItem("user", JSON.stringify({ token }));
  });

  const logout = useCallback(() => {
    setToken(null);
    setUserInfose({});
    localStorage.removeItem("user");
  });

  useEffect(() => {
    const localStorageData = JSON.stringify(localStorage.getItem("user"));
    setLocalStorageToken(localStorageData);
    if (!localStorageData) {
      fetch(``, {
        headers: {
          Authorization: `Bearer ${localStorageData.token}`,
        },
      })
        .then((res) => res.json())
        .then((userdata) => {
          setIsLoggedIn(true);
          setUserInfose(userdata);
        });
    }
  }, [login]);

  const router = useRoutes(routes);

  useEffect(() => {
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
      const decodedUser = parseJwt(jwt_token);
      const isTokenDeprecated = isDeprecated(jwt_token);
      if (isTokenDeprecated) {
        localStorage.removeItem("user");
        navigate(`/link/${decodedUser.UserName.split("user")[1]}`);
      }
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      setIsLoggedIn(true);
      setToken(localStorageData.token);
    } else {
      console.log("location.pathname", location.pathname);
      if (
        location.pathname.startsWith("/link/") &&
        location.pathname.split("/link/")[1] !== "" &&
        location.pathname.split("/link/")[1] !== "undefined"
      ) {
        return;
      } else {
        Swal.fire({
          icon: "info",
          title: "از طریق لینک اختصاصی خود اقدام به لاگین کنید",
        });
      }
      jwt_token = "no";
    }
  }, [location.pathname]);

  return (
    <>
      <Topbar />
      <div className="container">
        <SelectedGroupProvider>
          <AuthContext.Provider
            value={{
              isLoggedIn,
              token,
              userInfose,
              login,
              logout,
            }}
          >
            {/* {!isLoggedIn && <Navigate to="/login" />} */}
            <div className="wrapper">
              <Sidebar />
              <main>{router}</main>
            </div>

            {/*  <Sidebar />*/}
          </AuthContext.Provider>
        </SelectedGroupProvider>
      </div>
    </>
  );
}

export default App;
