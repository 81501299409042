import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import Home from "./pages/Home/Home";
import SignInSide from "./pages/LoginPage/Login";
import Tickets from "./pages/Tickets/Tickets";

let routes = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: "/tickets",
    element: (
      <PrivateRoute>
        <Tickets />
      </PrivateRoute>
    ),
  },

  { path: "/link/:code", element: <SignInSide /> },
];

export default routes;
