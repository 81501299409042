import axios from "axios";
import moment from "jalali-moment";

export function FormatExecutionString(inputstr) {
  if (typeof inputstr !== "string") {
    console.error("Invalid input. Please provide a valid string.");
    return "";
  }
  const steps = inputstr.split("\n");

  const formattedSteps = steps.map(
    (step) =>
      step &&
      `<li style="margin: 10px; list-style: inside;">${step.trim()}</li>`
  );

  const result = formattedSteps.join("\n");
  return result;
}

export function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error("Invalid token", e);
    return null;
  }
}

export function formatDate(dateString) {
  const jalaliDate = moment(dateString, "YYYY-MM-DDTHH:mm:ss.SSSZ")
    .locale("fa")
    .format("YYYY/MM/DD");

  return jalaliDate;
}

export function formatTime(dateString) {
  const formattedTime = moment(dateString).format("HH:mm");

  return formattedTime;
}

export function isDeprecated(token) {
  const decodedToken = parseJwt(token);

  const currentTime = Date.now() / 1000;
  if (decodedToken.exp < currentTime) {
    localStorage.removeItem("token");
    return true;
  }

  return false;
}
