import axios from "axios";
import config from "../../config";

import React, { useEffect, useState } from "react";
import "./Home.css";
import Modal from "../../Components/Modal/Modal";
import {
  formatDate,
  FormatExecutionString,
  formatTime,
  parseJwt,
} from "../../utils";
import SinglePackage from "../../Components/SinglePackage/SinglePackage";
import Loader from "../../Components/Loader/Loader";

function Home() {
  const [packages, setPackages] = useState();
  const [selectedPackageExercises, setselectedPackageExercises] = useState();
  const [packageEducations, setPackageEducations] = useState();
  const [filesss, setFilesss] = useState({ data: [] });
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  useEffect(() => {
    getPackageFromAPI();
  }, []);

  const getPackageFromAPI = () => {
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    let decodedUser = null;
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
      decodedUser = parseJwt(jwt_token);
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };

      axios
        .get(
          `https://api.bettermove.ir/api/v1/Patient/${
            decodedUser.UserName.split("user")[1] / 100
          }/Packages/GetActive`,
          {
            headers,
          }
        )
        .then((res) => {
          setPackages(res.data);
        });
    } else {
      jwt_token = "no";
    }
  };

  const getContentBypkg = (PkgID, isExercise) => {
    console.log("package id", PkgID);
    let jwt_token = "";
    const api = isExercise ? "Exercises/GetforPatient" : "Educations";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    axios
      .get(config.hostname + `/api/v1/Package/${PkgID}/${api}`, { headers })
      .then((res) => {
        console.log("result finally", res);
        if (isExercise) {
          axios
            .get(
              `https://api.bettermove.ir/api/v1/Package/GetPackageDeviders?packageId=${PkgID}`,
              { headers }
            )
            .then((deviders) => {
              const sortedDeviders = deviders.data.sort(
                (a, b) => a.rowIndex - b.rowIndex
              );
              sortedDeviders.map((devider) => [
                ...res.data.splice(devider.rowIndex, 0, devider),
              ]);
              console.log("all data test", res.data, deviders);
              setselectedPackageExercises(res.data);
            });
        } else {
          setPackageEducations(res.data);
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  function getfiles(ISexcer, keyID, educationInfo) {
    let apiaddress = "";
    if (ISexcer) {
      apiaddress = `/api/v1/Exercise/${keyID}/ExerciseFiles`;
    } else {
      apiaddress = `/api/v1/Education/${keyID}/EducationFiles`;
    }

    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
    } else {
      jwt_token = "no";
    }
    const authToken = jwt_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    axios
      .get(config.hostname + apiaddress, { headers })
      .then((response) =>
        setFilesss(
          ISexcer
            ? { data: response.data, type: "exercise" }
            : { data: response.data.result, type: "education", educationInfo }
        )
      )
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function showDetails(id, pkgid, exerid) {
    var details = document.getElementById(id);

    if (details.classList.contains("active")) {
      details.classList.remove("active");
    } else {
      details.classList.add("active");
      if (exerid == -1) {
        getContentBypkg(pkgid, 1);
        getContentBypkg(pkgid, 0);
      }
    }
  }

  function closeModal() {
    setFilesss({ data: [] });
  }

  return (
    <>
      {packages ? (
        packages.length > 0 ? (
          <div className="package-container">
            {packages.map((pkg) => (
              <div className="package" key={pkg.keyID}>
                <div className="package-header">
                  <h2>{pkg.packageName}</h2>
                  <div>
                    <h5>
                      تاریخ شروع: {formatTime(pkg.startDate)} |{" "}
                      {formatDate(pkg.startDate)}
                    </h5>
                    <h5>
                      تاریخ انقضا: {formatTime(pkg.endDate)} |{" "}
                      {formatDate(pkg.endDate)}
                    </h5>
                  </div>
                </div>

                <p>{pkg.packageDescription}</p>
                <button
                  onClick={() =>
                    showDetails(`details${pkg.keyID}`, pkg.keyID, -1)
                  }
                >
                  {isDetailsOpen ? "بستن جزئیات" : "مشاهده جزئیات"}
                </button>
                <div id={`details${pkg.keyID}`} className="details">
                  <h2>تمرینات</h2>
                  {selectedPackageExercises ? (
                    selectedPackageExercises.length > 0 ? (
                      selectedPackageExercises.map((exercise) => (
                        <SinglePackage
                          key={exercise.keyID}
                          {...exercise}
                          packageID={pkg.keyID}
                          getfiles={getfiles}
                        />
                      ))
                    ) : (
                      <p className="no-package-message">تمرینی یافت نشد</p>
                    )
                  ) : (
                    <Loader />
                  )}
                  <h2>آموزش‌ها</h2>

                  {packageEducations ? (
                    packageEducations.length > 0 ? (
                      packageEducations.map((educ) => (
                        <div key={educ.keyID} className="education">
                          <div className="div1">
                            <img src={educ.mainPicturePath} />
                          </div>
                          <div className="div2">
                            <div>
                              <p>{educ.title}</p>
                              <p>{educ.content}</p>
                            </div>
                            <div className="div3">
                              <button
                                onClick={() =>
                                  getfiles(false, educ.keyID, educ)
                                }
                                className="button"
                                id="myButton"
                              >
                                مشاهده
                              </button>
                            </div>
                          </div>
                          {/* <p>آدرس فایل: {educ.EduMainfilepath}</p> */}
                        </div>
                      ))
                    ) : (
                      <p className="no-package-message">آموزشی یافت نشد</p>
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="no-package-message">پکیجی یافت نشد</p>
        )
      ) : (
        <Loader />
      )}
      {filesss.data.length > 0 && (
        <Modal
          filesType={filesss.type}
          educationInfo={filesss.type === "education" && filesss.educationInfo}
          files={filesss.data}
          onClose={closeModal}
        />
      )}
    </>
  );
}
export default Home;
