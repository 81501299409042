import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const version = Math.floor(Math.random() * 1000000);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

document.getElementById("style-link").href = `/static/css/main.${version}.css`;
document.getElementById("script-tag").src = `/static/js/main.${version}.js`;
